<template>
    <div class="row">
        <div :class="[`col-12 my-3 d-flex justify-content-end`, { 'justify-content-between': showSelectAllWarning }]">
            <!-- show warning message if user checked selectAll on 1st page-->
            <p v-if="showSelectAllWarning" class="qa-select-all-in-student-list-message mb-0">
                <span>{{ PROVIDER.EducationalFulfillmentPage.SelectAllStudentsWarningText }}</span>
                <!-- select all in educational fulfillment list per total count returned from the server -->
                <recess-button
                    :title="PROVIDER.EducationalFulfillmentPage.SelectAllStudentsButton"
                    variant="variant4"
                    class="ml-1 qa-select-all-students-list-button"
                    @click.native.prevent="selectAllInStudentListHandler()"
                />
            </p>
        </div>

        <recess-table-flex class="qa-table-educational-fullfilment-list col-12" :table-options="setTableOptions" :pagination-options="setPaginationOptions" @getData="updateList">
            <recess-table-flex-row>
                <recess-table-flex-cell class="d-flex justify-content-end">
                    <recess-button
                        variant="secondary"
                        :title="PROVIDER.EducationalFulfillmentPage.ChangeSelectionStatusButton"
                        :disabled="!isStudentSelected || isFulfillmentModalVisible || isOleAccessModalVisible"
                        class="qa-open-edit-students-in-group-popup-button align-self-end"
                        @click.native.prevent="editStudentsInGroupHandler({ selectedStudents: selectedStudents })"
                    />
                </recess-table-flex-cell>
            </recess-table-flex-row>

            <recess-table-flex-head :set-fixed-header="true">
                <recess-table-flex-row>
                    <recess-table-flex-head-item cell-size="narrow">
                        <recess-checkbox-input
                            v-model="isCheckedAll"
                            name="select-all-students-per-page"
                            class="qa-select-all-students-per-page align-self-start"
                            variant="secondary"
                            :disabled="isEmptyTable"
                            @input="(newValue) => selectAllStudentsPerPageHandler(newValue)"
                        />
                    </recess-table-flex-head-item>
                    <recess-table-flex-head-item v-for="(th, index) in thead" :key="index" :class="th.className" :cell-size="th.cellSize">
                        {{ th.title }}
                    </recess-table-flex-head-item>
                </recess-table-flex-row>
            </recess-table-flex-head>

            <recess-table-flex-body>
                <recess-table-flex-row v-for="(tr, indextr) in students" :key="indextr">
                    <recess-table-flex-cell cell-size="narrow">
                        <recess-checkbox-input
                            v-model="tr.isChecked"
                            name="select-one-student-fulfillment-list"
                            :default-checked="tr.isChecked"
                            :class="`qa-select-one-student-fulfillment-list-${indextr}`"
                            variant="secondary"
                            @input="(newValue) => checkOneStudentHandler(tr, indextr)"
                        />
                    </recess-table-flex-cell>
                    <recess-table-flex-cell
                        :data-label="thead[0].title"
                        cell-size="medium"
                        :class="`qa-educational-fulfillment-list-student-name-${indextr} qa-educational-fulfillment-list-pagination-count`"
                        >{{ tr.studentName || '-' }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell :data-label="thead[1].title" cell-size="large" :class="`qa-educational-fulfillment-list-product-name-${indextr}`">{{
                        tr.productName || '-'
                    }}</recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="thead[2].title" cell-size="medium">
                        <div :class="`qa-educational-fulfillment-list-start-moment-id-${indextr}`">
                            {{ tr.startMomentId || '-' }}
                        </div>
                        <time :class="`qa-educational-fulfillment-list-start-moment-start-date-${indextr}`">{{ tr.startMomentDate | formatDate }}</time>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="thead[3].title" :class="`qa-educational-fulfillment-list-permanent-education-points-${indextr}`">{{
                        tr.permanentEducationPoints
                    }}</recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="thead[4].title" cell-size="medium">
                        <div>
                            <div :class="`mb-3 qa-educational-fulfillment-list-completed-${indextr}`">
                                {{ setCourseCompletedStatus(tr.completed) }}
                            </div>
                            <div :class="`qa-educational-fulfillment-list-exam-status-${indextr}`">
                                {{ tr.examStatusDisplayValue || '-' }}
                            </div>
                        </div>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="thead[5].title">
                        <div>
                            <div :class="`mb-3 qa-educational-fulfillment-list-date-completed-${indextr}`">
                                {{ formatDate(tr.dateCompleted) || '-' }}
                            </div>
                            <div :class="`qa-educational-fulfillment-list-date-diploma-expires-${indextr}`">
                                {{ formatDate(tr.dateDiplomaExpires) || '-' }}
                            </div>
                        </div>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell cell-size="large" class="d-flex flex-column">
                        <recess-button
                            variant="primary"
                            :title="PROVIDER.EducationalFulfillmentPage.EditButton"
                            :class="`qa-educational-fulfillment-list-edit-button-${indextr}`"
                            @click.native.prevent="editSingleStudentHandler(tr.id, tr.studentName)"
                        />
                        <recess-button
                            v-if="isOleUserButtonVisible"
                            variant="tertiary"
                            :title="PROVIDER.EducationalFulfillmentPage.OleUserButton"
                            :disabled="disableOleButtonForAdmin"
                            :class="`mt-2 qa-educational-fulfillment-list-ole-user-button-${indextr}`"
                            @click.native.prevent="oleAccessHandler(tr)"
                        />
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
        </recess-table-flex>

        <ole-access-popup-component
            :is-modal-visible="isOleAccessModalVisible"
            :ole-types-prop="provider.providerOleTypes"
            :educational-reference-prop="educationalReferenceData"
            @close="closeOleAccessModal"
        />

        <edit-educational-fulfillment-popup-component
            :is-modal-visible="isFulfillmentModalVisible"
            @close="closeEditFulfillmentModal"
            @submit="getListAfterRebuildIndex(WAIT_TIME.closeModal + WAIT_TIME.indexRebuild)"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'
import { PAGINATION, API_CALL_URL_PATHS, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { getItemsFromSearchIndex } from '../../../../../shared/api/SharedClient'
import UserService from '../../../../../shared/services/UserService'

const OleAccessPopupComponent = () => import('@/components/molecules/OleAccessPopupComponent')
const EditEducationalFulfillmentPopupComponent = () => import('@/components/molecules/EditEducationalFulfillmentPopupComponent')

import tableMixin from '@/../../shared/mixins/tableMixin'

export default {
    name: 'EducationalFulfillmentList',
    components: {
        OleAccessPopupComponent,
        EditEducationalFulfillmentPopupComponent
    },
    mixins: [tableMixin],
    props: {
        setSelectedFilters: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        let paginationOptions = [
            { displayText: '5', value: 5 },
            { displayText: '10', value: 10 },
            { displayText: '25', value: 25 },
            { displayText: '50', value: 50 },
            { displayText: '100', value: 100 },
            { displayText: '250', value: 250 }
        ]

        return {
            PROVIDER,
            userService: new UserService(),
            PAGINATION,
            WAIT_TIME,
            timeout: null,
            isAdministrator: false,
            isOleAccessModalVisible: false,
            isFulfillmentModalVisible: false,
            students: [],
            selectedStudents: [],
            isCheckedAll: false,
            selectAllInStudentList: false,
            showSelectAllWarning: false,
            thead: [
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.Student,
                    cellSize: 'medium'
                },
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.Product,
                    cellSize: 'large'
                },
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.StartmomentIdAndDate,
                    cellSize: 'medium'
                },
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.PermanentEducationPoints
                },
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.Status,
                    cellSize: 'medium'
                },
                {
                    title: PROVIDER.EducationalFulfillmentPage.TableHeaders.Date
                },
                {
                    title: '', // button column
                    cellSize: 'large'
                }
            ],
            filter: {
                search: '',
                searchMode: 'all',
                top: 0,
                skip: 0,
                orderBy: ['dateCreated desc'],
                filter: ''
            },
            educationalReferenceData: {},
            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                title: PROVIDER.EducationalFulfillmentPage.TableTitle,
                setCellSizes: true
            },
            setPaginationOptions: {
                showTopPagination: true,
                showBottomPagination: true,
                currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
                totalCount: null,
                selectOptions: paginationOptions,
                defaultTotalItemsPerPage: paginationOptions[3].value,
                itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[3].value,
                showSelectOption: true,
                selectOptionsLabel: PAGINATION.selectOptionsLabel,
                paginationShowLabel: PAGINATION.paginationShowLabel,
                paginationOfLabel: PAGINATION.paginationOfLabel
            }
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider', 'provider']),
        disableOleButtonForAdmin() {
            return this.isAdministrator && this.selectedProvider && !this.selectedProvider.id
        },
        isStudentSelected() {
            return this.selectedStudents.length > 0
        },
        isAllStudentsChecked() {
            return this.students && this.students.every((student) => student.isChecked)
        },
        isOleUserButtonVisible() {
            if (!this.provider.providerOleTypes) return false

            let isVisible = false

            const baseUrlIsFilledOnEveryOleType = this.provider.providerOleTypes.some((oleType) => oleType.baseUrl && oleType.hasSso)

            if (this.provider.providerOleTypes.length > 0 && baseUrlIsFilledOnEveryOleType) {
                isVisible = true
            }

            return isVisible
        },
        isEmptyTable() {
            return this.setPaginationOptions.totalCount === 0
        }
    },
    watch: {
        isAllStudentsChecked: {
            handler() {
                this.setIsCheckedAllStatus()
            }
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        resetSelectAllStudents() {
            this.selectedStudents = []
            this.showSelectAllWarning = false
            this.isCheckedAll = false
            this.selectAllInStudentList = false
        },
        getListAfterRebuildIndex(millisecond) {
            this.timeout = setTimeout(() => this.getDataOnPageChange(), millisecond)
            this.resetSelectAllStudents()
        },
        setCourseCompletedStatus(status) {
            return status ? PROVIDER.EducationalFulfillmentPage.Popup.EditEducationalFullfillment.FilterInput.Status.CourseCompletedStatus.Label : '-'
        },
        formatDate(date) {
            return formatUtcDateTimeToLocalDate(date)
        },
        updateList({ currentPage, itemsPerPage }) {
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
            this.setPaginationOptions.currentPage = currentPage

            this.filter.top = itemsPerPage
            this.filter.skip = currentPage * itemsPerPage - itemsPerPage
            this.filter.filter = 'orderCancelled eq false'
            this.filter.search = ''

            this.resetSelectAllStudents()

            this.getSelectedProvider()
            this.getSelectedExamStatus()
            this.getSelectedCompletedStatus()
            this.getSelectedStartmomentId()
            this.getSelectedSearchByName()

            // Get data from api
            this.getDataOnPageChange()
        },
        concatenateFilterString() {
            if (this.filter.filter.length > 0) {
                this.filter.filter += ` and `
            }
        },
        getSelectedProvider() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.providerId)) return

            this.concatenateFilterString()
            this.filter.filter += `providerReference eq 'provider:${this.setSelectedFilters.providerId}'`
        },
        getSelectedExamStatus() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.examStatus)) return

            this.concatenateFilterString()
            this.filter.filter += `examStatus eq '${this.setSelectedFilters.examStatus}'`
        },
        getSelectedCompletedStatus() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.courseCompletedStatus)) return

            this.concatenateFilterString()
            this.filter.filter += `completed eq ${this.setSelectedFilters.courseCompletedStatus}`
        },
        getSelectedStartmomentId() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.queryByStartmomentId)) return

            this.filter.search = `${this.setSelectedFilters.queryByStartmomentId}`
        },
        getSelectedSearchByName() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.queryByName)) return

            if (this.filter.search.length > 0) {
                this.filter.search += ` `
            }
            this.filter.search += this.setSelectedFilters.queryByName
        },
        getDataOnPageChange() {
            this.getEducationalFulfillmentList()
            this.setCurrentPageAsQueryParam()
        },
        async getEducationalFulfillmentList() {
            try {
                const response = await getItemsFromSearchIndex(process.env.VUE_APP_FULFILLMENT_API_URL, API_CALL_URL_PATHS.educationalFulfillments, API_CALL_URL_PATHS.search, this.filter)
                if (!response) return
                this.setInitialCheckboxState(response.results)

                this.setPaginationOptions.totalCount = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving educational fulfillment records', error)
            }
        },
        oleAccessHandler(studentRecord) {
            if (!studentRecord) return

            this.educationalReferenceData = {
                providerReferenceId: studentRecord.providerReference.split(':')[1],
                educationalCareerId: studentRecord.educationalCareerId,
                educationalFulfillmentId: studentRecord.id
            }

            this.isOleAccessModalVisible = true
        },
        closeOleAccessModal() {
            this.isOleAccessModalVisible = false
        },
        showEditFulfillmentModal() {
            this.isFulfillmentModalVisible = true
        },
        closeEditFulfillmentModal() {
            this.isFulfillmentModalVisible = false
        },
        setInitialCheckboxState(results) {
            this.students = [] // reset students
            results.map((result) => {
                const studentRecord = result.document
                // set isChecked status every time when educational fulfillment list is refreshed
                this.setIsCheckedStatus(studentRecord)
                this.students.push(studentRecord)
                // set selectAll button status every time when educational fulfillment list is refreshed
                this.setIsCheckedAllStatus()
                return null
            })
        },
        setIsCheckedAllStatus() {
            if (this.isAllStudentsChecked && !this.isEmptyTable) {
                this.isCheckedAll = true
            } else {
                this.isCheckedAll = false
            }
        },
        editSingleStudentHandler(educationalFulfillmentId, studentName) {
            if (!educationalFulfillmentId) return
            this.$bus.emit('educational-fulfillment-single-student-reference', { educationalFulfillmentId, studentName })
            this.showEditFulfillmentModal()
        },
        editStudentsInGroupHandler(selectedStudents) {
            this.$bus.emit('educational-fulfillment-students-in-group-reference', selectedStudents)
            this.showEditFulfillmentModal()
        },
        setIsCheckedStatus(studentRecord) {
            const isStudentChecked = this.selectedStudents.find((selectedStudent) => selectedStudent.educationalFulfillmentId === studentRecord.id)
            if (isStudentChecked) {
                studentRecord.isChecked = true
            } else {
                studentRecord.isChecked = false
            }
        },
        checkOneStudentHandler(student, index) {
            if (this.students[index].isChecked) {
                const studentDetails = {
                    educationalFulfillmentId: student.id,
                    studentName: student.studentName,
                    productName: student.productName,
                    startMomentId: student.startMomentId,
                    isChecked: student.isChecked,
                    startMomentDate: student.startMomentDate
                }
                this.selectedStudents.push(studentDetails)
            } else {
                this.selectedStudents = this.selectedStudents.filter((selectedStudent) => selectedStudent.educationalFulfillmentId !== student.id)
                this.isCheckedAll = false
            }
        },
        setSelectAllWarningVisibility() {
            if (this.isCheckedAll) {
                this.showSelectAllWarning = true
            } else {
                this.showSelectAllWarning = false
            }
        },
        selectAllStudentsPerPageHandler() {
            this.setSelectAllWarningVisibility()
            this.students.map((student) => {
                if (this.isCheckedAll && !student.isChecked) {
                    student.isChecked = true

                    const studentDetails = {
                        educationalFulfillmentId: student.id,
                        studentName: student.studentName,
                        productName: student.productName,
                        startMomentId: student.startMomentId,
                        isChecked: student.isChecked,
                        startMomentDate: student.startMomentDate
                    }
                    this.selectedStudents.push(studentDetails)
                }

                if (!this.isCheckedAll) {
                    this.selectedStudents = this.selectedStudents.filter((selectedStudent) => selectedStudent.educationalFulfillmentId !== student.id)
                    student.isChecked = false
                }

                return null
            })
        },
        async selectAllInStudentListHandler() {
            this.filter.top = 99999
            this.filter.skip = 0
            await this.getEducationalFulfillmentList()
            this.selectAllInStudentList = true
            this.students.map((student) => {
                student.isChecked = true
                student.educationalFulfillmentId = student.id
                return null
            })
            // send all students from server to pop up
            this.editStudentsInGroupHandler({ selectedStudents: this.students })
        }
    }
}
</script>
